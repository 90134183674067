<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style>
@import 'css_wp.css';

@font-face {
  font-family: ATSackersHeavyGothic;
  src: url(assets/fonts/ATSackersHeavyGothic.otf);
}

#app {
  font-family: ATSackersHeavyGothic, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
ul{
  margin-left: 20px;
}

.page-width {
  margin-top: 50px;
  width: 80%;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
