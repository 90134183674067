<template>
  <div>
    <Header />
    <b-row align-h="around" class="chategory-menu" id="cluster-menu">
        <b-col class="chategory-menu-box" cols="3">
          <b-row cols="1">
          <router-link class="chategory-menu-element" to="/clusterresearch">RESEARCH</router-link>
          <router-link class="chategory-menu-element" to="/clusterinnovation">INNOVATION</router-link>
          <router-link class="chategory-menu-element" to="/clusterservices">SERVICES</router-link>
          <router-link class="chategory-menu-element" to="/clustermembersandpartners">MEMBERS AND PARTNERS</router-link>
          <router-link class="chategory-menu-element" to="/clusterpublicprograms">PUBLIC PROGRAMS</router-link>
          <router-link class="chategory-menu-element" to="/clusterpublications">PUBLICATIONS</router-link>
          </b-row>
        </b-col>
        <b-col class="chategory-menu-label" cols="5">
          <b-row cols="1">
          <label class="chategory-small-label cluster-label">HEALTH EXPERIENCE</label>
          <label class="chategory-big-label cluster-label">CLUSTER</label>
          </b-row>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import Header from './Header'
export default {
  name: 'ClusterSubHeader',
  components: {
    Header
  }
}
</script>
<style>
@import 'SubHeaderStyle.css';
#cluster-menu {
  background-image: url("../assets/headers/cluster_hatterkep.png");
}
.cluster-label {
  color: #28a9e1;
}
</style>
