<template>
  <div>
    <b-row id="header-box" align-h="center" cols="9">
      <img
        @click="open('https://www.facebook.com/', '_blank')"
        class="social"
        src="@/assets/social-media/Facebook.png"
      />
      <img
        @click="open('https://www.instagram.com/', '_blank')"
        class="social"
        src="@/assets/social-media/insta.png"
      />
      <img
        @click="open('https://messenger.com/', '_blank')"
        class="social"
        src="@/assets/social-media/messenger.png"
      />
      <img
        @click="open('https://www.youtube.com/', '_blank')"
        class="social"
        src="@/assets/social-media/youtube.png"
      />
      <router-link to="/">
        <img id="logo-text" src="@/assets/logo/HEI_logo_fulltext.png" />
      </router-link>
      <b-form-group id="search-login-box" label label-cols="2">
        <router-link style="text-decoration:none;" to="/search">
          <b-form class="search-login" inline>
            <label class="login-search-text">Search</label>
            <img id="search-img" src="@/assets/pictograms/search.png" />
          </b-form>
        </router-link>
        <!-- <router-link to="/Login">
          <b-form class="search-login" inline>
            <label class="login-search-text">Login</label>
            <img id="login-img" src="@/assets/pictograms/login.png" />
          </b-form>
        </router-link>-->
      </b-form-group>
    </b-row>
  </div>
</template>
<script>
export default {
  name: 'Header',
  methods: {
    open (url, target) {
      window.open(url, target)
    }
  }
}
</script>
<style>
.social {
  width: 75px;
  height: 75px;
  opacity: 0.5;
  align-self: start;
}
.social:hover {
  cursor: pointer;
  opacity: 1;
}
#logo-text {
  width: 1;
  height: 136px;
  margin-left: 100px;
  margin-right: 100px;
}
#logo-text:hover {
  cursor: pointer;
}
#search-login-box {
  align-self: start;
  opacity: 0.6;
}
.search-login {
  cursor: pointer;
  width: 250px;
  border-bottom: lightblue;
  border-bottom-style: solid;
  border-left: lightblue;
  border-left-style: solid;
  margin: 5px;
}
.login-search-text {
  text-decoration-line: none;
  cursor: pointer;
  font-size: larger;
  padding-left: 20px;
  padding-right: 100px;
  color: lightblue;
}
.login-search-text:hover {
  text-decoration-line: none;
  cursor: pointer;
}
#login-img {
  cursor: pointer;
  margin-top: 5px;
  margin-left: 30px;
}
@media (max-width: 400px) {
  #header-box {
    margin-left: 0px;
  }
  #logo-text {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media (min-width: 400px) and (max-width: 500px) {
  #header-box {
    margin-left: 0px;
    margin-right: 0px;
  }
  #logo-text {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
