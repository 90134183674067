import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import ClusterInnovation from './views/cluster/Innovation.vue'
import ClusterMembersAndPartners from './views/cluster/MembersAndPartners.vue'
import ClusterPublications from './views/cluster/Publications.vue'
import ClusterPublicPrograms from './views/cluster/PublicPrograms.vue'
import ClusterResearch from './views/cluster/Research.vue'
import ClusterServices from './views/cluster/Services.vue'
import FounderArtTherapy from './views/founder/ArtTherapy.vue'
import FounderHealth from './views/founder/Health.vue'
import FounderSocialInnovation from './views/founder/SocialInnovation.vue'
import FounderSport from './views/founder/Sport.vue'
import FounderTrainingAndEducation from './views/founder/TrainingAndEducation.vue'
import AboutUs from './views/footer/AboutUs.vue'
import ContactUs from './views/footer/ContactUs.vue'
import Legal from './views/footer/Legal.vue'
/* import Login from './views/Login.vue' */
import Search from './views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/clusterinnovation',
    name: 'ClusterInnovation',
    component: ClusterInnovation
  },
  {
    path: '/clustermembersandpartners',
    name: 'ClusterMembersAndPartners',
    component: ClusterMembersAndPartners
  },
  {
    path: '/clusterpublications',
    name: 'ClusterPublications',
    component: ClusterPublications
  },
  {
    path: '/clusterpublicprograms',
    name: 'ClusterPublicPrograms',
    component: ClusterPublicPrograms
  },
  {
    path: '/clusterresearch',
    name: 'ClusterResearch',
    component: ClusterResearch
  },
  {
    path: '/clusterservices',
    name: 'ClusterServices',
    component: ClusterServices
  },
  {
    path: '/portalblog',
    name: 'PortalBlog',
    beforeEnter () { location.href = process.env.VUE_APP_BLOG_URL }
  },
  {
    path: '/founderarttherapy',
    name: 'FounderArtTherapy',
    component: FounderArtTherapy
  },
  {
    path: '/founderhealth',
    name: 'FounderHealth',
    component: FounderHealth
  },
  {
    path: '/foundersocialinnovation',
    name: 'FounderSocialInnovation',
    component: FounderSocialInnovation
  },
  {
    path: '/foundersport',
    name: 'FounderSport',
    component: FounderSport
  },
  {
    path: '/foundertrainingandeducation',
    name: 'FounderTrainingAndEducation',
    component: FounderTrainingAndEducation
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  /* {
    path: '/Login',
    name: 'Login',
    component: Login
  }, */
  {
    path: '/search',
    name: 'Search',
    component: Search
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
