<template>
    <div>
      <Header />
        <label>LEGAL</label>
        <b-row align-h="center" v-if="page">
          <div class="page-width" v-html="page.content.rendered"></div>
        </b-row>
      <Footer />
    </div>
</template>
<style scoped>
label {
  margin-top: 100px;
  font-size: 50px;
}
</style>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { getPage } from '@/services/backendService'
export default {
  name: 'Legal',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      page: null
    }
  },
  async mounted () {
    const response = await getPage('Legal')
    this.page = response.data[0]
  }
}
</script>
