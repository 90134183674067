<template>
  <div>
    <Header />
    <b-row align-h="around" class="chategory-menu" id="founder-menu">
        <b-col class="chategory-menu-box" cols="3">
          <b-row cols="1">
          <router-link class="chategory-menu-element" to="/founderhealth">HEALTH</router-link>
          <router-link class="chategory-menu-element" to="/foundersocialinnovation">SOCIAL INNOVATION</router-link>
          <router-link class="chategory-menu-element" to="/founderarttherapy">ART THERAPY</router-link>
          <router-link class="chategory-menu-element" to="/foundertrainingandeducation">TRAINING AND EDUCATION</router-link>
          <router-link class="chategory-menu-element" to="/foundersport">SPORT</router-link>
          </b-row>
        </b-col>
        <b-col class="chategory-menu-label" cols="5">
          <b-row cols="1">
          <label class="chategory-small-label founder-label">HEALTH EXPERIENCE</label>
          <label class="chategory-big-label founder-label">FOUNDER</label>
          </b-row>
        </b-col>
    </b-row>
  </div>
</template>
<script>
import Header from './Header'
export default {
  name: 'FounderSubHeader',
  components: {
    Header
  }
}
</script>
<style>
@import 'SubHeaderStyle.css';
#founder-menu {
  background-image: url("../assets/headers/founder_hatterkep.png");
}
.founder-label {
  color: #ef0000;
}
</style>
