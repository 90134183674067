<template>
  <div>
    <ClusterSubHeader />
    <b-row align-h="center" v-if="page">
      <div class="page-width" v-html="page.content.rendered"></div>
    </b-row>
    <Footer />
  </div>
</template>
<script>
import ClusterSubHeader from '@/components/ClusterSubHeader.vue'
import Footer from '@/components/Footer'
import { getPage } from '@/services/backendService'
export default {
  name: 'ClusterServices',
  data () {
    return {
      page: null
    }
  },
  components: {
    ClusterSubHeader,
    Footer
  },
  async mounted () {
    const response = await getPage('ClusterServices')
    this.page = response.data[0]
  }
}
</script>
