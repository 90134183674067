<template>
  <div>
    <Header />
    <b-row align-h="center" cols="2">
      <div class="search-form">
        <label id="search-text">Search</label>
        <b-input-group>
          <b-form-input @keyup.enter="search()" v-model="search_input" id="input-search"></b-form-input>
          <b-input-group-append>
            <b-button @click="search()" variant="info">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      <div class="search_result" v-if="result_posts || result_pages">
        <label class="type_title" v-if="result_pages.length > 0">Found on pages</label>
        <ul>
          <li v-for="(result, index) in result_pages" :key="result+index">
            <div class="title">
              <router-link v-html="result.title.rendered"
                v-bind:to="{ path: '/' + result.slug}"
              >{{result.title.rendered}}</router-link>
            </div>
            <div class="excerpt" v-html="result.excerpt.rendered"></div>
          </li>
        </ul>
        <label class="type_title" v-if="result_posts.length > 0">Found on posts</label>
        <ul>
          <li v-for="(result, index) in result_posts" :key="result+index">
            <div class="title">
              <b-link v-html="result.title.rendered"
              @click="open(result.slug)"
              >{{result.title.rendered}}</b-link>
            </div>
            <div class="excerpt" v-html="result.excerpt.rendered"></div>
          </li>
        </ul>
        <label class="type_title" v-if="!result_pages && !result_posts">Not found on posts or pages</label>
      </div>
      </div>
    </b-row>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { getPostSearch, getPageSearch } from '@/services/backendService'
export default {
  name: 'Search',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      search_input: '',
      result_posts: null,
      result_pages: null
    }
  },
  methods: {
    async search () {
      const responsePost = await getPostSearch(this.search_input)
      this.result_posts = responsePost.data
      const responsePage = await getPageSearch(this.search_input)
      this.result_pages = responsePage.data
    },
    open (slug) {
      window.open('https://heiresearch.com/blog/index.php/' + slug, '_self')
    }
  }
}
</script>
<style scoped>
.search-form {
  width: 60%;
  margin-top: 8%;
  margin-bottom: 8%;
}
#search-text {
  font-size: 50px;
}
#input-search {
  font-family: Arial, Helvetica, sans-serif;
}
.title {
  font-size: large;
}
.search_result {
  text-align: left;
  margin-top: 50px;
}
.excerpt {
  font-family: Arial, Helvetica, sans-serif;
}
.type_title {
  font-size: xx-large;
}
li{
  list-style-type: none;
}
.result-post-title{
  color: #167AF6;
}
.result-post-title:hover{
  cursor: pointer;
  text-decoration: underline;
  color: #0C56B3;
}
</style>
