import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'

const backendService = axios.create({
  baseURL: process.env.VUE_APP_BLOG_URL + '/index.php/wp-json/wp/v2',
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
})

export const getPage = async (slug) => {
  slug = slug.toLowerCase()
  return backendService.get('/pages?slug=' + slug + '&_fields=id,content')
}

export const getPostSearch = async (searchString) => {
  return backendService.get('/posts?search=' + searchString + '&_fields=id,slug,excerpt,title,type')
}

export const getPageSearch = async (searchString) => {
  return backendService.get('/pages?search=' + searchString + '&_fields=id,slug,excerpt,title,type')
}
