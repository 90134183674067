<template>
  <div class="home">
    <Header />
    <b-form-group id="menu-boxes">
      <b-row align-h="center" cols="1" cols-sm="2" cols-md="3" cols-lg="4">
        <b-form-group class="menu-box">
          <b-row class="menu-title" cols="1">
            <label class="small-menu-title cluster-title">HEALTH EXPERIENCE</label>
            <label class="big-menu-title cluster-title">CLUSTER</label>
          </b-row>
          <b-row class="menu" id="cluster" cols="1">
            <router-link class="menu-element" to="/clusterresearch">RESEARCH</router-link>
            <router-link class="menu-element" to="/clusterinnovation">INNOVATION</router-link>
            <router-link class="menu-element" to="/clusterservices">SERVICES</router-link>
            <router-link class="menu-element" to="/clustermembersandpartners">MEMBERS AND PARTNERS</router-link>
            <router-link class="menu-element" to="/clusterpublicprograms">PUBLIC PROGRAMS</router-link>
            <router-link class="menu-element" to="/clusterpublications">PUBLICATIONS</router-link>
          </b-row>
        </b-form-group>
        <b-form-group class="menu-box">
          <b-row class="menu-title" cols="1">
            <label class="small-menu-title portal-title">HEALTH EXPERIENCE</label>
            <label class="big-menu-title portal-title">PARTNERS</label>
          </b-row>
          <b-row class="menu" id="portal" cols="1">
            <a class="menu-element" href="https://heiresearch.com/PATHed/">PATHeD PROJECT</a>
            <a class="menu-element" href="https://www.holosurge.eu/">HOLOSURGE</a>
            <a class="menu-element" href="https://semmelweis.hu/">SEMMELWEIS</a>
          </b-row>
        </b-form-group>
        <b-form-group class="menu-box">
          <b-row class="menu-title" cols="1">
            <label class="small-menu-title founder-title">HEALTH EXPERIENCE</label>
            <label class="big-menu-title founder-title">FOUNDER</label>
          </b-row>
          <b-row class="menu" id="founder" cols="1">
            <router-link class="menu-element" to="/founderhealth">HEALTH</router-link>
            <router-link class="menu-element" to="/foundersocialinnovation">SOCIAL INNOVATION</router-link>
            <router-link class="menu-element" to="/founderarttherapy">ART THERAPY</router-link>
            <router-link
              class="menu-element"
              to="/FounderTrainingAndEducation"
            >TRAINING AND EDUCATION</router-link>
            <router-link class="menu-element" to="/foundersport">SPORT</router-link>
          </b-row>
        </b-form-group>
      </b-row>
    </b-form-group>
    <img style="width: 100%" src="@/assets/pictograms/HEI_illustration.png" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  }
}
</script>
<style scoped>
#menu-boxes {
  margin-top: 100px;
}
.menu-box {
  margin: 30px;
}
.menu {
  height: 400px;
  opacity: 0.3;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
}
.menu:hover {
  opacity: 1;
}
.small-menu-title {
  font-size: large;
}
.big-menu-title {
  font-size: xx-large;
}
.cluster-title {
  color: #28a9e1;
}
.portal-title {
  color: #a7d002;
}
.founder-title {
  color: #ef0000;
}
#cluster {
  background: #28a9e1;
}
#portal {
  background: #a7d002;
  padding-bottom: 245px;
}
#founder {
  background: #ef0000;
  padding-bottom: 70px;
}
.menu-element {
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  color: white;
  font-variant: small-caps;
  font-size: larger;
}
#cluster .menu-element:hover {
  background: white;
  color: #28a9e1;
  text-decoration-line: none;
}
#portal .menu-element:hover {
  background: white;
  color: #a7d002;
  text-decoration-line: none;
}
#founder .menu-element:hover {
  background: white;
  color: #ef0000;
  text-decoration-line: none;
}
@media (max-width: 400px) {
  .menu-box {
  margin: 30px 0px;
}
  .menu {
    margin-left: 30px;
    margin-right: 15px;
  }
    .menu-title{
    width: 100%;
  }
  .big-menu-title,
  .small-menu-title {
    margin-left: 15px;
  }
}
@media (min-width: 400px) and (max-width: 575px) {
  .menu-box {
  margin: 30px 0px;
}
  .menu {
    margin-left: 30px;
    margin-right: 30px;
  }
  .menu-title{
    width: 100%;
  }
  .big-menu-title,
  .small-menu-title {
    margin-right:0px;
    margin-left: 15px;
  }
}
</style>
